// auth.js
import Auth0Lock from 'auth0-lock';

const Auth = (function() {
  const wm = new WeakMap();
  const privateStore = {};

  function Auth() {
    // Simplified Lock configuration
    this.lock = new Auth0Lock(
      'W3AubSGmOsfrK09fILBbXwm5qPIomAkf',
      'datap-auth.uk.auth0.com',
      {
        auth: {
          redirectUrl: window.location.origin,
          responseType: 'token id_token',
          params: {
            scope: 'openid profile email'
          }
        },
        container: 'auth0-lock-container',
        theme: {
          logo: 'https://placehold.co/200x200',
          primaryColor: '#000000'
        },
        languageDictionary: {
          title: 'datap'
        },
        closable: true,
        allowSignUp: true
      }
    );

    wm.set(privateStore, {
      appName: 'datap',
      profile: null,
      accessToken: null,
      idToken: null
    });

    // Try to restore session from localStorage
    const storedProfile = localStorage.getItem('profile');
    const storedAccessToken = localStorage.getItem('accessToken');
    const storedIdToken = localStorage.getItem('idToken');
    
    if (storedProfile && storedAccessToken && storedIdToken) {
      wm.set(privateStore, {
        ...wm.get(privateStore),
        profile: JSON.parse(storedProfile),
        accessToken: storedAccessToken,
        idToken: storedIdToken
      });
    }
  }

  Auth.prototype.handleAuthentication = function(callback) {
    this.lock.on('authenticated', (authResult) => {
      console.log('Authenticated:', authResult);
      this.lock.getUserInfo(authResult.accessToken, (error, profile) => {
        if (error) {
          console.error('Error getting user info:', error);
          return;
        }

        console.log('Got user profile:', profile);
        const privateData = wm.get(privateStore);
        wm.set(privateStore, {
          ...privateData,
          accessToken: authResult.accessToken,
          idToken: authResult.idToken,
          profile: profile
        });

        // Store in localStorage
        localStorage.setItem('accessToken', authResult.accessToken);
        localStorage.setItem('idToken', authResult.idToken);
        localStorage.setItem('profile', JSON.stringify(profile));

        if (callback) {
          callback(profile);
        }
      });
    });

    this.lock.on('authorization_error', (error) => {
      console.error('Authorization error:', error);
    });

    this.lock.on('unrecoverable_error', (error) => {
      console.error('Unrecoverable error:', error);
    });
  };

  Auth.prototype.login = function() {
    console.log('Showing lock dialog...');
    this.lock.show();
  };

  Auth.prototype.logout = function() {
    const privateData = wm.get(privateStore);
    wm.set(privateStore, {
      ...privateData,
      profile: null,
      accessToken: null,
      idToken: null
    });

    // Clear stored tokens
    localStorage.removeItem('accessToken');
    localStorage.removeItem('idToken');
    localStorage.removeItem('profile');

    // Redirect to Auth0 logout
    this.lock.logout({
      returnTo: window.location.origin
    });
  };

  Auth.prototype.getProfile = function() {
    const privateData = wm.get(privateStore);
    return privateData.profile;
  };

  Auth.prototype.getAccessToken = function() {
    const privateData = wm.get(privateStore);
    return privateData.accessToken;
  };

  Auth.prototype.getIdToken = function() {
    const privateData = wm.get(privateStore);
    return privateData.idToken;
  };

  Auth.prototype.isAuthenticated = function() {
    const privateData = wm.get(privateStore);
    return !!privateData.profile && !!privateData.accessToken;
  };

  return Auth;
})();

export const auth = new Auth();