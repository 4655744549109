// LoginForm.js - Updated version
import React, { useEffect } from 'react';
import { auth } from './auth';

function LoginForm({ onLogin }) {
  useEffect(() => {
    // Set up authentication handling
    auth.handleAuthentication((profile) => {
      onLogin(profile);
    });

    // Check if already authenticated
    if (auth.isAuthenticated()) {
      onLogin(auth.getProfile());
    } else {
      // Create a container if it doesn't exist
      let container = document.getElementById('auth0-lock-container');
      if (!container) {
        container = document.createElement('div');
        container.id = 'auth0-lock-container';
        document.body.appendChild(container);
      }
      
      // Start the login process
      auth.login();
    }

    // Cleanup
    return () => {
      if (auth.lock) {
        auth.lock.hide();
      }
      // Remove the container on unmount
      const container = document.getElementById('auth0-lock-container');
      if (container) {
        container.remove();
      }
    };
  }, [onLogin]);

  // Return a container div that will be used by Auth0 Lock
  return (
    <div className="h-64">
      <div id="auth0-lock-container" className="auth0-lock-container"></div>
    </div>
  );
}

export default LoginForm;