import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { useTable } from 'react-table';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { MentionsInput, Mention } from 'react-mentions';
import { Download, Upload, FileCheck, Plus, Trash2 } from 'lucide-react';
import { Split } from '@geoffcox/react-splitter';
import { parse } from "csv-parse/browser/esm";
import LoginForm from './LoginForm';
import { auth } from './auth';
import './mentions.css';

//API and request settings

const API_URL = process.env.NODE_ENV === 'development'
  ? 'http://localhost:12895'
  : 'https://wispy-morning-7180.oacoleshill.workers.dev';

// Create an axios instance with default headers
const api = axios.create({
  baseURL: API_URL,
});

// Add a request interceptor to include the token in every request
api.interceptors.request.use((config) => {
  const token = auth.getIdToken();
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

const PREVIEW_CHUNK_SIZE = 1024 * 1024; // 1MB chunk for preview

const fetchStatus = async (jobId) => {
	console.log('Getting status');
  if (!jobId) return null;
  const response = await api.get(`/status/${jobId}`);
  console.log(response.data);
  return response.data;
};

const fetchUserFiles = async () => {
  const response = await api.get('/user-files');
  return response.data;
};

function CSVProcessor() {
  const [previewData, setPreviewData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [leftPaneWidth, setLeftPaneWidth] = useState(30);
  const [fileKey, setFileKey] = useState(null);
  const [pollInterval, setPollInterval] = useState(1000);
  const fileInputRef = useRef(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated());
  const [setUser] = useState(null);
  const queryClient = useQueryClient();
  const [userFiles, setUserFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [tasks, setTasks] = useState([{ type: 'AI response', prompt: '', searchQuery: '', imageQuery: '' }]);
  const [currentStep, setCurrentStep] = useState(1);
  const [jobId, setJobId] = useState(null);

  // User admin
	const handleLogin = (profile) => {
	  setIsAuthenticated(true);
	  setUser(profile);
	  queryClient.invalidateQueries('userFiles');
	};

	const handleLogout = () => {
	  auth.logout();
	  setIsAuthenticated(false);
	  setUser(null);
	};

	const parseCSV = useCallback((csvData) => {
	  parse(csvData, {
	    columns: true,
	    skip_empty_lines: true,
	    trim: true,
	  }, (err, records) => {
	    if (err) {
	      console.error("Error parsing CSV:", err);
	      return;
	    }
	    const headers = Object.keys(records[0]);
	    setColumns(headers.map(header => ({
	      id: header,
	      display: header,
	    })));
	    setPreviewData(records);
	  });
	}, []);

	const loadPreview = useCallback(async (file) => {
	  try {
	    let csvData;

	    if (file instanceof File) {
	      // Local file preview for newly uploaded files
	      csvData = await readFileChunk(file);
	    } else if (file.fileKey) {
	      // Preview for previously uploaded files
	      const response = await api.get(`/preview/${file.fileKey}`, {
	        responseType: 'text',
	      });
	      csvData = response.data;
	    }

	    if (csvData) {
	      // Parse and load the CSV data into the preview
	      parseCSV(csvData);
	    } else {
	      console.error("No data available for preview.");
	    }
	  } catch (error) {
	    console.error("Error loading preview:", error);
	    alert('Error loading preview. Please try again.');
	  }
	}, [parseCSV]);

  const userFilesQuery = useQuery('userFiles', fetchUserFiles, {
    enabled: isAuthenticated,
    onSuccess: (data) => {
      setUserFiles(data.files || []);
    },
  });

	const handleFileSelect = useCallback((file) => {
	  if (!file || file.fileKey === fileKey) return;

	  // Update the selected file and its key
	  setSelectedFile(file);
	  setFileKey(file.fileKey);
	  
	  // Reset any previous processing states
	  setIsProcessing(false);
	  setIsComplete(false);

	  // Load the preview for the selected file
	  loadPreview(file);
	}, [fileKey, loadPreview]);

	useEffect(() => {
	  if (selectedFile) {
	    loadPreview(selectedFile);
	  }
	}, [selectedFile, loadPreview]);

  const handleAddTask = () => {
    setTasks([...tasks, { type: 'AI response', prompt: '', searchQuery: '' }]);
  };

  const handleRemoveTask = (index) => {
    const newTasks = tasks.filter((_, i) => i !== index);
    setTasks(newTasks);
  };

  const handleTaskChange = (index, field, value) => {
    const newTasks = [...tasks];
    newTasks[index][field] = value;
    setTasks(newTasks);
  };

	const statusQuery = useQuery(
	  ['status', jobId],
	  () => fetchStatus(jobId),
	  {
	    enabled: !!jobId && (isProcessing || isComplete),
	    refetchInterval: isProcessing ? pollInterval : false,
	    onSuccess: (data) => {
	      if (data.status === 'completed') {
	        setIsComplete(true);
	        setIsProcessing(false);
	      } else if (data.status === 'processing') {
	        setIsProcessing(true);
	      }
	      //setPollInterval(Math.min(pollInterval * 1.5, 10000));
	      setPollInterval(Math.min(pollInterval, 10000));
	    },
	    onError: (error) => {
	      console.error('Status query error:', error);
	    },
	  }
	);

  const trimContent = (content, maxLength = 50) => {
    if (typeof content !== 'string') return content;
    return content.length > maxLength ? content.substr(0, maxLength) + '...' : content;
  };

  const uploadMutation = useMutation(async (formData) => {
    const response = await api.post('/upload', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  }, {
    onSuccess: (data) => {
      setFileKey(data.fileKey);
      setUploadSuccess(true);
      console.log('File uploaded:', data);
      // Refresh the user files list
      queryClient.invalidateQueries('userFiles');
    },
    onError: (error) => {
      console.error('Upload error:', error);
      setUploadSuccess(false);
    },
  });

	const startProcessingMutation = useMutation(async ({ fileKey, config }) => {
	  const response = await api.post('/start', { fileKey, config });
	  return response.data;
	}, {
	  onSuccess: (data) => {
	    setIsProcessing(true);
	    setJobId(data.jobId); // Store the jobId
	    console.log('Processing started:', data);
	  },
	  onError: (error) => {
	    console.error('Processing start error:', error);
	  },
	});

	const handleFileChange = useCallback((event) => {
	  const selectedFile = event.target.files[0];

	  // Handle new CSV file uploads
	  if (selectedFile && selectedFile.type === 'text/csv') {
	    setSelectedFile(selectedFile);
	    setIsProcessing(false);
	    setIsComplete(false);
	    setFileKey(null);  // Clear fileKey for new upload
	    loadPreview(selectedFile);  // Trigger preview for the new file

	    // Automatically upload the file
	    const formData = new FormData();
	    formData.append('file', selectedFile);
	    uploadMutation.mutate(formData);
	  } else if (selectedFile) {
	    // Non-CSV file selected, reset the file input and show an error
	    alert('Please select a CSV file.');
	    if (fileInputRef.current) {
	      fileInputRef.current.value = '';
	    }
	  }
	}, [loadPreview, uploadMutation]);

	const readFileChunk = (file) => {
	  return new Promise((resolve, reject) => {
	    const reader = new FileReader();
	    reader.onload = (e) => resolve(e.target.result);
	    reader.onerror = reject;
	    reader.readAsText(file.slice(0, PREVIEW_CHUNK_SIZE));
	  });
	};

  const formatPrompt = useCallback((prompt) => {
    return prompt.replace(/@\[([^\]]+)\]\(([^)]+)\)/g, '{{COLUMN:$2}}');
  }, []);

	const handleStartProcessing = useCallback(() => {
	  if (fileKey && tasks.length > 0) {
	    const config = tasks.map(task => ({
	      type: task.type,
	      prompt: formatPrompt(task.prompt),
	      searchQueryTemplate: formatPrompt(task.searchQuery || ''),
	      imageQueryTemplate: formatPrompt(task.imageQuery || '')
	    }));

	    // Create the full payload
	    const payload = {
	      fileKey,
	      config
	    };

	    // Print the payload to the console
	    console.log('Sending request payload to /start:', JSON.stringify(payload, null, 2));

	    // Send the request to the server
	    startProcessingMutation.mutate(payload);
	  }
	}, [fileKey, tasks, startProcessingMutation, formatPrompt]);

	const memoizedData = useMemo(() => {
	  if (
	    statusQuery.data &&
	    statusQuery.data.data &&
	    statusQuery.data.data.length > 0
	  ) {
	    // Merge the preview data with the processed data
	    return previewData.map((row, index) => ({
	      ...row,
	      ...(statusQuery.data.data[index] || {}),
	    }));
	  }
	  return previewData;
	}, [statusQuery.data, previewData]);

	const memoizedColumns = useMemo(() => {
	  if (memoizedData.length > 0) {
	    const allKeys = new Set(memoizedData.flatMap(Object.keys));
	    const originalKeys = new Set(Object.keys(previewData[0]));
	    
	    const newKeys = Array.from(allKeys).filter(key => !originalKeys.has(key));
	    const stickyColumnCount = Math.min(newKeys.length, 4);
	    
	    const columns = Array.from(allKeys).map(key => {
	      const isNewColumn = newKeys.includes(key);
	      const shouldBeSticky = isNewColumn && newKeys.indexOf(key) < stickyColumnCount;
	      
	      return {
	        Header: key,
	        accessor: key,
	        sticky: shouldBeSticky ? 'right' : false,
	        stickyIndex: shouldBeSticky ? stickyColumnCount - newKeys.indexOf(key) - 1 : undefined,
					Cell: ({ value }) => {
					  const trimmedValue = trimContent(value);
					  return (
					    <div className="relative group">
					      <span className="truncate">{trimmedValue}</span>
					      {value !== trimmedValue && (
					        <div
					          className="absolute z-[9999] invisible group-hover:visible
					                     bg-white p-2 rounded shadow-lg max-w-xs break-words
					                     left-0 top-full mt-1 border border-gray-200"
					        >
					          {value}
					        </div>
					      )}
					    </div>
					  );
					},
	      };
	    });

	    console.log('Columns configuration:', columns);

	    return columns;
	  }
	  return [];
	}, [memoizedData, previewData]);

  const tableInstance = useTable({ 
    columns: memoizedColumns, 
    data: memoizedData
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

	const handleDownload = useCallback(async () => {
	  if (statusQuery.data && statusQuery.data.downloadUrl) {
	    try {
	      const response = await api.get(statusQuery.data.downloadUrl, {
	        responseType: 'blob',
	      });
	      const url = window.URL.createObjectURL(new Blob([response.data]));
	      const link = document.createElement('a');
	      link.href = url;
	      link.setAttribute('download', `processed_${fileKey}.csv`);
	      document.body.appendChild(link);
	      link.click();
	      link.parentNode.removeChild(link);
	    } catch (error) {
	      console.error('Download error:', error);
	      alert('Error downloading file. Please try again.');
	    }
	  }
	}, [statusQuery.data, fileKey]);

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="mb-6">
            <h2 className="text-xl font-semibold mb-4">Step 1: Upload or Select File</h2>
            <input 
              type="file" 
              onChange={handleFileChange} 
              className="mb-4 block w-full text-sm text-gray-500
                file:mr-4 file:py-2 file:px-4
                file:rounded-full file:border-0
                file:text-sm file:font-semibold
                file:bg-blue-50 file:text-blue-700
                hover:file:bg-blue-100"
              accept=".csv" 
            />
            {uploadSuccess && (
              <p className="text-green-600 mt-2">File uploaded successfully!</p>
            )}

            <div className="mb-4">
              <h3 className="text-lg font-semibold mb-2">Your Files:</h3>
              {userFilesQuery.isLoading ? (
                <p>Loading your files...</p>
              ) : userFilesQuery.isError ? (
                <p>Error loading your files. Please try again.</p>
              ) : (
                <ul className="space-y-2">
                  {userFiles.map((file) => (
                    <li 
                      key={file.fileKey}
                      className={`flex items-center p-2 rounded cursor-pointer ${selectedFile && selectedFile.fileKey === file.fileKey ? 'bg-blue-100' : 'hover:bg-gray-100'}`}
                      onClick={() => handleFileSelect(file)}
                    >
                      <FileCheck className="mr-2" size={20} />
                      <span>{file.fileName}</span>
                      <span className="ml-auto text-sm text-gray-500">{new Date(file.uploadDate).toLocaleDateString()}</span>
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <button
              onClick={() => setCurrentStep(2)}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              disabled={!fileKey}
            >
              Next
            </button>
          </div>
        );
      case 2:
        return (
					<div className="mb-6">
					  <h2 className="text-xl font-semibold mb-4">Step 2: Configure Tasks</h2>
					  {tasks.map((task, index) => (
					    <div key={index} className="mb-8 p-4 border rounded">
					      <div className="flex justify-between items-center mb-4">
					        <select
					          value={task.type}
					          onChange={(e) => handleTaskChange(index, 'type', e.target.value)}
					          className="block w-full max-w-xs rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
					        >
					          <option value="AI response">AI response</option>
					          <option value="AI response with Search">AI response with Search</option>
					          <option value="Image Search">Image Search</option>
					        </select>
					        <button
					          onClick={() => handleRemoveTask(index)}
					          className="text-red-500 hover:text-red-700"
					        >
					          <Trash2 size={20} />
					        </button>
					      </div>
					      {/* First input box for AI response and with search */}
                {(task.type === 'AI response' || task.type === 'AI response with Search') && (
                  <MentionsInput
                    value={task.prompt}
                    onChange={(e, newValue) => handleTaskChange(index, 'prompt', newValue)}
                    placeholder="Enter your prompt here... Use @ to reference columns"
                    className="mentions mb-4"
                    a11ySuggestionsListLabel={"Suggested columns"}
                    style={{
                      input: {
                        minHeight: '100px',
                        padding: '10px',
                      },
                    }}
                  >
                    <Mention
                      trigger="@"
                      data={columns}
                      className="mentions__mention"
                      renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
                        <div className={`p-2 ${focused ? 'bg-blue-200' : ''}`}>
                          {highlightedDisplay}
                        </div>
                      )}
                    />
                  </MentionsInput>
                )}
                {/* Search query input for AI response with Search */}
                {task.type === 'AI response with Search' && (
                  <MentionsInput
                    value={task.searchQuery}
                    onChange={(e, newValue) => handleTaskChange(index, 'searchQuery', newValue)}
                    placeholder="Enter search query... Use @ to reference columns"
                    className="mentions w-full"
                    a11ySuggestionsListLabel={"Suggested columns"}
                    style={{
                      input: {
                        minHeight: '100px',
                        padding: '10px',
                      },
                    }}
                  >
                    <Mention
                      trigger="@"
                      data={columns}
                      className="bg-blue-100 rounded"
                      renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
                        <div className={`p-2 ${focused ? 'bg-blue-200' : ''}`}>
                          {highlightedDisplay}
                        </div>
                      )}
                    />
                  </MentionsInput>
                )}
                {/* Image search query input */}
                {task.type === 'Image Search' && (
                  <MentionsInput
                    value={task.imageQuery}
                    onChange={(e, newValue) => handleTaskChange(index, 'imageQuery', newValue)}
                    placeholder="Enter image search query... Use @ to reference columns"
                    className="mentions w-full"
                    a11ySuggestionsListLabel={"Suggested columns"}
                    style={{
                      input: {
                        minHeight: '100px',
                        padding: '10px',
                      },
                    }}
                  >
                    <Mention
                      trigger="@"
                      data={columns}
                      className="bg-blue-100 rounded"
                      renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
                        <div className={`p-2 ${focused ? 'bg-blue-200' : ''}`}>
                          {highlightedDisplay}
                        </div>
                      )}
                    />
                  </MentionsInput>
                )}
              </div>
					  ))}
					  <button
					    onClick={handleAddTask}
					    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex items-center"
					  >
					    <Plus size={20} className="mr-2" />
					    Add Task
					  </button>
					  <div className="mt-4 flex space-x-4">
					    <button
					      onClick={() => setCurrentStep(1)}
					      className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
					    >
					      Previous
					    </button>
					    <button
					      onClick={() => setCurrentStep(3)}
					      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
					      disabled={tasks.length === 0}
					    >
					      Next
					    </button>
					  </div>
					</div>
        );
      case 3:
        return (
          <div className="mb-6">
            <h2 className="text-xl font-semibold mb-4">Step 3: Process and Download</h2>
            {statusQuery.data && (
              <div className="mb-4">
                <p className="mb-2">Status: {statusQuery.data.status}</p>
                <p className="mb-2">Progress: {statusQuery.data.progress.toFixed(2)}%</p>
                <div className="w-full bg-gray-200 rounded-full h-2.5">
                  <div
                    className="bg-blue-600 h-2.5 rounded-full"
                    style={{ width: `${statusQuery.data.progress}%` }}
                  ></div>
                </div>
                {statusQuery.data.status === 'completed' && (
                  <p className="mt-2 text-green-600 font-semibold">Processing complete!</p>
                )}
              </div>
            )}
            
            <div className="flex space-x-4 mb-4">
              <button
                onClick={handleStartProcessing}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex-grow"
                disabled={startProcessingMutation.isLoading || !fileKey}
              >
                {startProcessingMutation.isLoading ? 'Processing...' : 'Start Processing'}
              </button>
              
              <button
                onClick={handleDownload}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex-grow flex items-center justify-center"
                disabled={!statusQuery.data || !statusQuery.data.downloadUrl}
              >
                <Download className="mr-2" size={20} />
                Download CSV
              </button>
            </div>
            <button
              onClick={() => setCurrentStep(2)}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
            >
              Previous
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (statusQuery.data) {
      setIsComplete(statusQuery.data.status === 'processed');
      if (statusQuery.data.status === 'processed') {
        setIsProcessing(false);
        // Trigger a one-time fetch of the processed data
        queryClient.invalidateQueries(['data', fileKey]);
      }
    }
  }, [statusQuery.data, fileKey, queryClient]);

  if (!isAuthenticated) {
    return (
      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold mb-6 text-center">da+ap</h1>
        <LoginForm onLogin={handleLogin} />
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4 max-w-full h-screen flex flex-col">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold mb-6">da+ap</h1>
        <div>
          <button
            onClick={handleLogout}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          >
            Log Out
          </button>
        </div>
      </div>
      
      <Split
        initialPrimarySize={`${leftPaneWidth}%`}
        minPrimarySize="20%"
        minSecondarySize="50%"
        splitterSize="10px"
        onSplitDragEnd={(secondarySize) => setLeftPaneWidth(100 - secondarySize)}
        className="flex-grow overflow-hidden"
      >
        {/* Left Panel: Step-by-step process */}
        <div className="pr-4 overflow-y-auto h-full flex flex-col">
          <div className="flex-grow">
            {renderStep()}
          </div>
        </div>

        {/* Right Panel: CSV Preview or Upload Box */}
        <div className="pl-4 overflow-y-visible h-full">
          {selectedFile ? (
            <div className="mb-6 h-full flex flex-col">
              <h2 className="text-xl font-semibold mb-4">
                {isProcessing ? 'Processing Data:' : (isComplete ? 'Processed Data:' : 'CSV Preview:')}
              </h2>
              {statusQuery.isLoading ? (
                <p>Loading data...</p>
              ) : statusQuery.isError ? (
                <p>Error loading data: {statusQuery.error.message}</p>
              ) : (
                <div className="overflow-x-auto overflow-y-visible shadow-md sm:rounded-lg flex-grow">
								  <table {...getTableProps()} className="w-full text-sm text-left text-gray-500">
								    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
								      {headerGroups.map(headerGroup => (
								        <tr {...headerGroup.getHeaderGroupProps()}>
								          {headerGroup.headers.map(column => (
								            <th
								              key={column.id || column.Header}
								              scope="col"
								              {...column.getHeaderProps({ key: undefined })}
								              className={`px-6 py-3 ${column.sticky ? 'sticky bg-gray-50 z-10' : ''}`}
								              style={column.sticky ? { right: `${column.stickyIndex * 150}px` } : {}}
								            >
								              {column.render('Header')}
								            </th>
								          ))}
								        </tr>
								      ))}
								    </thead>
								    <tbody {...getTableBodyProps()}>
								      {rows.map(row => {
								        prepareRow(row);
								        return (
								          <tr {...row.getRowProps()} className="bg-white border-b hover:bg-gray-50">
								            {row.cells.map(cell => (
															<td
															  {...cell.getCellProps()}
															  className={`px-6 py-4 group ${cell.column.sticky ? 'sticky bg-white z-10' : ''}`}
																style={cell.column.sticky ? { right: `${cell.column.stickyIndex * 150}px` } : {}}
															>
															  {cell.render('Cell')}
															</td>
								            ))}
								          </tr>
								        );
								      })}
								    </tbody>
								  </table>
								</div>
              )}
            </div>
          ) : (
            <div className="flex items-center justify-center h-full">
              <div className="text-center">
                <Upload className="mx-auto h-12 w-12 text-gray-400" />
                <h3 className="mt-2 text-sm font-medium text-gray-900">No CSV file uploaded</h3>
                <p className="mt-1 text-sm text-gray-500">Upload a CSV file to start processing</p>
                <div className="mt-6">
                  <label htmlFor="file-upload" className="cursor-pointer rounded-md bg-white font-medium text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2 hover:text-blue-500">
                    <span>Upload a file</span>
                    <input 
                      id="file-upload" 
                      name="file-upload" 
                      type="file" 
                      className="sr-only" 
                      onChange={handleFileChange}
                      accept=".csv"
                    />
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
      </Split>
    </div>
  );
}

export default CSVProcessor;